var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"iot-login-bg"},[_c('a-row',{staticClass:"iot-login",attrs:{"type":"flex","justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"iot-login-center",attrs:{"span":6}},[_c('div',{staticClass:"iot-login-form"},[_c('h1',{staticClass:"iot-login-title"},[_vm._v(" 物联网业务管理平台 ")]),_c('div',{staticClass:"login-border"},[_c('div',{staticClass:"login-content"},[_c('div',{staticClass:"iot-login-welcome"},[_vm._v(" 欢迎登录 ")]),_c('br'),_c('a-form',{attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['username', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { required: true, message: '请输入登录账号' }
                    ]
                  }]),expression:"['username', {\n                    normalize: this.$lodash.trim,\n                    rules: [\n                      { required: true, message: '请输入登录账号' }\n                    ]\n                  }]"}],attrs:{"placeholder":"账号"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {
                    rules: [
                      { required: true, message: '请输入登录密码' }
                    ]
                  }]),expression:"['password', {\n                    rules: [\n                      { required: true, message: '请输入登录密码' }\n                    ]\n                  }]"}],attrs:{"type":"password","placeholder":"密码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":18}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['captcha', {
                        normalize: this.$lodash.trim,
                        rules: [{ required: true, message: '请输入验证码' }]
                      }]),expression:"['captcha', {\n                        normalize: this.$lodash.trim,\n                        rules: [{ required: true, message: '请输入验证码' }]\n                      }]"}],attrs:{"placeholder":"验证码"}})],1),_c('a-col',{attrs:{"span":6}},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" 点击刷新验证码 ")]),_c('a-spin',{attrs:{"spinning":_vm.loadingCaptchaImg}},[(_vm.captchaId.length > 0)?_c('img',{staticClass:"captcha-img simiot-cursor-pointer",attrs:{"src":_vm.captchaImgUrl,"alt":""},on:{"click":_vm.reloadCaptcha,"load":_vm.captchaImgLoaded}}):_vm._e()])],2)],1)],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"iot-login-form-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.submitting}},[_vm._v(" 登录 ")])],1)],1)],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }